// https://onerecord.iata.org/ns/cargo#VolumetricWeight
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LogisticsObject from "./LogisticsObject";
import Value from "./Value";

export default function VolumetricWeight({ key, data }) {
  const template = [];
  if ("https://onerecord.iata.org/ns/cargo#chargeableWeight" in data) {
    template.push(
      <span>
        <OverlayTrigger
          overlay={
            <Tooltip
              id={"https://onerecord.iata.org/ns/cargo#chargeableWeight"}
            >
              {"https://onerecord.iata.org/ns/cargo#chargeableWeight"}
            </Tooltip>
          }
        >
          <span style={{ paddingRight: "5px" }}> 
            {"https://onerecord.iata.org/ns/cargo#chargeableWeight"
              .split("#")
              .pop()}:
          </span>
        </OverlayTrigger>
        <Value
          data={data["https://onerecord.iata.org/ns/cargo#chargeableWeight"]}
        />
      </span>
    );
  }
  if(template.length>0) {
    template.push(<br/>);
  }
  if ("https://onerecord.iata.org/ns/cargo#conversionFactor" in data) {
    template.push(
      <span>
        <OverlayTrigger
          overlay={
            <Tooltip
              id={"https://onerecord.iata.org/ns/cargo#conversionFactor"}
            >
              {"https://onerecord.iata.org/ns/cargo#conversionFactor"}
            </Tooltip>
          }
        >
          <span style={{ paddingRight: "5px" }}> 
            {"https://onerecord.iata.org/ns/cargo#conversionFactor"
              .split("#")
              .pop()}
          </span>
        </OverlayTrigger>
        <Value
          data={data["https://onerecord.iata.org/ns/cargo#conversionFactor"]}
        />
      </span>
    );
  }
  

  return <>{template}</>;
}
