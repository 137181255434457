import { useState, useEffect } from 'react';
import {
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JSONLDTable from './LinkedDataViewer/JSONLDTable';
import PlainJSONLD from './LinkedDataViewer/PlainJSONLD';
import { callApi, getONERecordServerURL } from '../App';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';

export default function ServerInformation() {
  const { instance, inProgress, accounts } = useMsal();
 
  const [serverInformationData, setServerInformationData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  var logisticsAgentURI;
  var oneRecordServerAPI = null;
  if (accounts !== undefined && accounts.length > 0) {
    logisticsAgentURI = accounts[0].idTokenClaims.logisticsAgentURI;    
    
    if(logisticsAgentURI !== undefined) {      
      oneRecordServerAPI = logisticsAgentURI.split("/logistics-objects/")[0];      
    }
  }  

  useEffect(() => {
    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    if (!serverInformationData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.idToken;          
          setLoading(true);
          callApi(accessToken, oneRecordServerAPI).then((response) => {                 
            setServerInformationData(response);
            setLoading(false);
          });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error(error);
        });
    }
  }, [instance, accounts, inProgress, serverInformationData]);
  

  if (isLoading) return <p>Loading...</p>;
  if (!serverInformationData) return <p>ServerInformation could not be loaded</p>;

  const tableIcon = <FontAwesomeIcon icon={['fas', 'table']} />;
  const codeIcon = <FontAwesomeIcon icon={['fas', 'code']} />;

  return (
    <div>
      <Row>
        <Col xs={2} />
        <Col xs={8}>
          <div className="list-heading">Server Information</div>
          <div className="pt-4">
            <Tabs
              defaultActiveKey="tabular-view"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="tabular-view"
                title={(
                  <span>
                    {tableIcon}
                    {' '}
                    Table
                  </span>
)}
              >
                <JSONLDTable data={serverInformationData} />
              </Tab>
              <Tab
                eventKey="json-ld-view"
                title={(
                  <span>
                    {codeIcon}
                    {' '}
                    JSON-LD
                  </span>
)}
              >
                <PlainJSONLD data={serverInformationData} />
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
}
