import { useState, useEffect } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import PlainJSONLD from "./LinkedDataViewer/PlainJSONLD";
import JSONLDTable from "./LinkedDataViewer/JSONLDTable";
import GraphViewer from "./LinkedDataViewer/GraphViewer";
import * as jsonld from "jsonld";
import LogisticsEventTable from "./LogisticsEventTable";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { callApi } from "../App";


export default function LogisticsObjectDetails() {
  const { instance, inProgress, accounts } = useMsal();
  var logisticsAgentURI;
  var oneRecordServerAPI;
  if (accounts !== undefined && accounts.length > 0) {
    logisticsAgentURI = accounts[0].idTokenClaims.logisticsAgentURI;    
    
    if(logisticsAgentURI !== undefined) {      
      oneRecordServerAPI = logisticsAgentURI.split("/logistics-objects/")[0];
    }
  }  

  const { logisticsObjectId } = useParams();

  const url = `${oneRecordServerAPI}/logistics-objects/${logisticsObjectId}`;
  const logisticsEventsURL = `${url}/logistics-events`;

  const [data, setData] = useState(null);
  const [logisticsEvents, setLogisticsEvents] = useState(null);
  const [latestRevision, setLatestRevision] = useState(null);
  const [lastModified, setLastModified] = useState(null);
  const [nQuads, setNQuads] = useState(null);
  const [edges, setEdges] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {        
    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.idToken;          
          setLoading(true)
          callApi(accessToken, `${url}`).then((response) => {            
            setData(response);
            setLoading(false);
          });

          callApi(accessToken, `${logisticsEventsURL}`).then((response) => {            
            setLogisticsEvents(response);      
          });

      
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error(error);
        });
    }
  }, [instance, accounts, inProgress, logisticsObjectId]);




  

  useEffect(() => {
    const transformData = async () => {
      const nquads = await jsonld.toRDF(data, {
        format: "application/n-quads",
      });
      setNQuads(nquads);
      const statements = nquads.split("\n");
      const regex = /<(.*?)>/gm;

      let nodes = [];
      let edges = [];

      statements.forEach((statement) => {
        if (statement.includes("<")) {
          var result = statement.match(/<(.*?)>/g).map(function (val) {
            return val.replace("<", "").replace(">", "");
          });
          if (result.length == 3) {
            if (result[0].includes("/logistics-objects/")) {
              nodes.push({
                id: result[0],
                type: "custom",
                sourcePosition: "right",
                position: { x: 0, y: 0 },
                data: {
                  label: result[0].split("/logistics-objects/").pop(),
                },
              });
            }

            if (
              result[0].includes("/logistics-objects/") &&
              result[2].includes("/logistics-objects/")
            ) {
              nodes.push({
                id: result[2],
                type: "output",
                targetPosition: "left",
                position: { x: 350, y: (edges.length - 0) * 90 },
                data: {
                  label: result[2].split("/logistics-objects/").pop(),
                },
              });

              edges.push({
                id: edges.length,
                source: result[0],
                target: result[2],
                label: result[1].split("#").pop(),
              });
            }
          }
        }
      });
      setNodes(nodes);
      setEdges(edges);
    };
    transformData().catch(console.error);
  }, [data]);

  if (isLoading) return <p>Loading...</p>;
  if (!data) return <p>Data could not be loaded</p>;

  const tableIcon = <FontAwesomeIcon icon={["fas", "table"]} />;
  const codeIcon = <FontAwesomeIcon icon={["fas", "code"]} />;
  const barsIcon = <FontAwesomeIcon icon={["fas", "bars"]} />;
  const graphIcon = <FontAwesomeIcon icon={["fas", "diagram-project"]} />;
  const renderLogisticsEventsTable = () => {
    if (logisticsEvents !== undefined && logisticsEvents !== null) {
      let logisticsEventsData = [];
      if ("@graph" in logisticsEvents) {
        logisticsEventsData = logisticsEvents["@graph"];
      } else if (
        "@type" in logisticsEvents &&
        logisticsEvents["@type"].endsWith("#LogisticsEvent")
      ) {
        logisticsEventsData = [logisticsEvents];
      } else {
        return <p>No LogisticsEvents found</p>;
      }
      return (
        <Tabs
          defaultActiveKey="tabular-view"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="tabular-view" title={<span>{tableIcon} Table</span>}>
            <LogisticsEventTable data={logisticsEventsData} />
          </Tab>
          <Tab eventKey="json-ld-view" title={<span>{codeIcon} JSON-LD</span>}>
            <PlainJSONLD data={logisticsEvents} />
          </Tab>
        </Tabs>
      );
    }
    return <p>No LogisticsEvents found</p>;
  };
  return (
    <div>
      <Row>
        <Col md={2} />
        <Col md={8}>
          <div className="list-heading">
            LogisticsObjects
            <FontAwesomeIcon
              icon={["fas", "caret-right"]}
              className="ps-1 pe-1"
            />
            <span style={{ fontWeight: "normal" }}> {url}</span>
          </div>
          {latestRevision ? (
            <span style={{ paddingRight: "15px" }}>
              <FontAwesomeIcon icon={["fas", "file"]} /> Latest-Revision:{" "}
              {latestRevision}
            </span>
          ) : (
            ""
          )}
          {lastModified ? (
            <span style={{ paddingRight: "15px" }}>
              <FontAwesomeIcon className="ps-2" icon={["fas", "clock"]} /> Last
              Modified: {new Date(lastModified).toISOString().split('.')[0]+"Z"}
            </span>
          ) : (
            ""
          )}
          <div className="pt-4">
            <Tabs
              defaultActiveKey="tabular-view"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="tabular-view"
                title={<span>{tableIcon} Table</span>}
              >
                <JSONLDTable data={data} />
              </Tab>
              <Tab
                eventKey="json-ld-view"
                title={<span>{codeIcon} JSON-LD</span>}
              >
                <PlainJSONLD data={data} />
              </Tab>

              <Tab eventKey="graph-view" title={<span>{graphIcon} Graph</span>}>
                <GraphViewer nodes={nodes} edges={edges} />
              </Tab>

              <Tab
                eventKey="rdf-nquads-view"
                title={<span>{barsIcon} RDF (N-Quads)</span>}
              >
                <textarea
                  rows={15}
                  cols={170}
                  value={nQuads}
                  style={{ fontSize: "0.8rem" }}
                  readOnly
                />
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col md={2} />
        <Col md={8}>
          <div className="list-heading">
            LogisticsEvents
            <FontAwesomeIcon
              icon={["fas", "caret-right"]}
              className="ps-1 pe-1"
            />
            <span style={{ fontWeight: "normal" }}>
              {" "}
              {url}
              /logistics-events
            </span>
          </div>

          <div className="pt-4">{renderLogisticsEventsTable()}</div>
        </Col>
      </Row>
    </div>
  );
}
