import { useState, useEffect } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { callApi, getONERecordServerURL } from '../App';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';

function toTitleCase(text) {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export default function LogisticsObjects() {
  const { instance, inProgress, accounts } = useMsal();
  var logisticsAgentURI;
  var oneRecordServerAPI;
  if (accounts !== undefined && accounts.length > 0) {
    logisticsAgentURI = accounts[0].idTokenClaims.logisticsAgentURI;    
    
    if(logisticsAgentURI !== undefined) {      
      oneRecordServerAPI = logisticsAgentURI.split("/logistics-objects/")[0];
    }
  }  
  const baseURL = `${getONERecordServerURL()}/extra/logistics-objects`;
  const [data, setData] = useState(null);
  const [filterOptions, setFilterOptions] = useState({ logisticsObjectTypes: {} });
  const [page, setPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    var url = baseURL;
    if ('logisticsObjectTypes' in selectedFilters && selectedFilters.logisticsObjectTypes.length > 0) {
      url = `${baseURL}?logisticsObjectTypes=${encodeURIComponent(selectedFilters.logisticsObjectTypes[0])}`;
    }

    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.idToken;          
          setLoading(true);
          callApi(accessToken, `${url}`).then((response) => {            
            setData(response);
            setLoading(false);
          });

        

          callApi(accessToken, `${baseURL}/filter-options`).then((response) => {                  
            setFilterOptions(response);      
          });
        

      
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error(error);
        });
    }
  }, [instance, accounts, inProgress, selectedFilters]);
  
    


  if (isLoading) return <p>Loading...</p>;
  if (!data) return <p>Data could not be loaded</p>;

  function isActiveFilter(selectedFilters, key) {
    return selectedFilters !== undefined && selectedFilters !== null && 'logisticsObjectTypes' in selectedFilters && selectedFilters.logisticsObjectTypes.includes(key);
  }

  return (
    <div>
      <Row>
        <Col md={2} style={{ paddingLeft: '30px' }}>
          <div className="filter-heading">Filter</div>
          <div className="pt-4">
            <FontAwesomeIcon icon={['fas', 'caret-down']} />
            {' '}
            <b><u>Type</u></b>
          </div>
          <ul>
            {Object.keys(filterOptions.logisticsObjectTypes).sort((a, b) => (a > b ? 1 : -1)).map((key) => (
              <Row
                className="filter-entry"
                key={key}
                onClick={() => {
                  if (isActiveFilter(selectedFilters, key)) {
                    // reset filter
                    setSelectedFilters({
                      logisticsObjectTypes: [],
                    });
                  } else {
                    setSelectedFilters({
                      logisticsObjectTypes: [key],
                    });
                  }
                }}
              >
                <Col xs={8} className={isActiveFilter(selectedFilters, key) ? 'filter-active' : ''}>
                  {toTitleCase(key.split('#').pop())}
                </Col>
                <Col style={{ color: '#b0b0b0', fontSize: '0.9em' }}>
                  {filterOptions.logisticsObjectTypes[key]}
                </Col>
              </Row>
            ))}
          </ul>
        </Col>
        <Col md={8}>
          <div className="list-heading">Logistic Objects</div>
          <div className="pt-4">
            {Object.keys(data).map((key, index) => {
              const logisticsObjectId = data[key].logisticsObjectURI.split('/logistics-objects/').pop();
              return (
                <Row key={key} className="list-row">
                  <Col
                    className="pb-3 pt-3"
                    style={{
                      borderTop: '1px solid #e1e5eb',
                      borderBottom: '1px solid #e1e5eb',
                    }}
                  >
                    <Badge
                      style={{ width: '130px', float: 'right' }}
                      bg="primary"
                    >
                      {toTitleCase(data[key].logisticsObjectType.split('#').pop())}
                    </Badge>
                    <Link style={{ fontWeight: 'bold' }} to={`/logistics-objects/${logisticsObjectId}`}>{data[key].logisticsObjectURI}</Link>
                    <br />
                    <FontAwesomeIcon icon={['fas', 'file']} />
                    {' '}
                    Latest Revision: {data[key]['latestRevision']}
                    <FontAwesomeIcon className="ps-2" icon={['fas', 'clock']} />
                    {' '}
                    {/* Last-Modified: {new Date(data[key]['lastModified']).toISOString().split('.')[0]+"Z"} */}
                  </Col>
                </Row>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
}
