import Country from "./Country";

export default function Address({ key, data }) {
  const template = [];
  if ("https://onerecord.iata.org/ns/cargo#addressCodeType" in data) {
    template.push(
      <span style={{ paddingRight: "5px", fontWeight: "bold" }}>
        {data["https://onerecord.iata.org/ns/cargo#addressCodeType"]}
        <br />
      </span>
    );
  }
  if ("https://onerecord.iata.org/ns/cargo#addressCode" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#addressCode"]}
      </span>
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#streetAddressLines" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#streetAddressLines"]}
      </span>
    );
  }


  if ("https://onerecord.iata.org/ns/cargo#postOfficeBox" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#postOfficeBox"]}
      </span>
    );
  }

 

  if(template.length>0) {
    template.push(<br/>);
  }

  if ("https://onerecord.iata.org/ns/cargo#postalCode" in data) {
    template.push(
      <span style={{ paddingRight: "5px"}}>
        {data["https://onerecord.iata.org/ns/cargo#postalCode"]}        
      </span>
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#cityName" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#cityName"]}
      </span>
    );
  }
  if ("https://onerecord.iata.org/ns/cargo#cityCode" in data) {
    template.push(
      <span style={{ paddingRight: "5px"}}>
        ({data["https://onerecord.iata.org/ns/cargo#cityCode"]})
        <br />
      </span>
    );
  }

  if(template.length>0) {
    template.push(<br/>);
  }

  if ("https://onerecord.iata.org/ns/cargo#regionName" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#regionName"]}
      </span>
    );
  }
  if ("https://onerecord.iata.org/ns/cargo#regionCode" in data) {
    template.push(
      <span style={{ paddingRight: "5px"}}>
        ({data["https://onerecord.iata.org/ns/cargo#regionCode"]})
      </span>
    );
  }
  if(template.length>0) {
    template.push(<br/>);
  }

  if ("https://onerecord.iata.org/ns/cargo#country" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        <Country data={data["https://onerecord.iata.org/ns/cargo#country"]} />
      </span>
    );
  }

  return <>{template}</>;
}
