import { Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Node from "./LinkedDataViewer/Node";

function LogisticsEventTable(props) {
  const logisticsEvents = props.data;
  return (
    <Table striped bordered hover>
      <Row>
        <Col md={3}>
          <b>Property</b>
        </Col>
        <Col>
          <b>Value</b>
        </Col>
      </Row>
      <tbody>
        {logisticsEvents.map((item, i) => (
          <tr key={i}>
            <td>
              {Object.keys(item).map((key, index) => (
                <Row>
                  <Col xs={2}>
                    {" "}
                    <OverlayTrigger overlay={<Tooltip id={key}>{key}</Tooltip>}>
                      <div>{key.split("#").pop()}</div>
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    <Node data={item[key]} />
                  </Col>
                </Row>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
LogisticsEventTable.propTypes = {};
export default LogisticsEventTable;