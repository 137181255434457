// https://onerecord.iata.org/ns/cargo#Party

import LogisticsObject from "./LogisticsObject";
import Value from "./Value";

export default function Party({ key, data }) {
  const template = [];
  if ("https://onerecord.iata.org/ns/cargo#role" in data) {
    template.push(
      <span style={{ paddingRight: "5px", fontWeight: "bold" }}>
        {data["https://onerecord.iata.org/ns/cargo#role"]}
      </span>
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#organization" in data) {
    template.push(
      <LogisticsObject
        data={data["https://onerecord.iata.org/ns/cargo#organization"]}
      />
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#otherIdentifiers" in data) {
    template.push(
      <span style={{ paddingLeft: "5px" }}>
        ({data["https://onerecord.iata.org/ns/cargo#otherIdentifiers"]})
      </span>
    );
  }

  return <>{template}</>;
}
