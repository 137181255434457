export default function ContactDetails({ key, data }) {
  const template = [];
  if ("https://onerecord.iata.org/ns/cargo#contactDetailType" in data) {
    template.push(
      <span style={{ paddingRight: "5px", fontWeight: "bold" }}>
        {data["https://onerecord.iata.org/ns/cargo#contactDetailType"]}
        <br />
      </span>
    );
  }
  if ("https://onerecord.iata.org/ns/cargo#textualValue" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#textualValue"]}
      </span>
    );
  }

  return <>{template}</>;
}
