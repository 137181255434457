import ReactJson from 'react-json-view';

export default function PlainJSONLD({ data }) {
  return (
    <div>
      <ReactJson style={{ backgroundColor: 'rgb(0 0 0 / 1%)', border: '0.5px solid rgb(222, 226, 230)', padding: '15px' }} iconStyle="square" src={data} name={null} displayArrayKey={false} displayObjectSize={false} displayDataTypes={false} />
    </div>

  );
}
