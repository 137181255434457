import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import { useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';

function signOutClickHandler(instance) {
  const logoutRequest = {
    account: instance.getAccountByHomeId(),
  };
  instance.logoutRedirect(logoutRequest);
}

// SignOutButton Component returns a button that invokes a popup logout when clicked
function SignOutButton() {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance } = useMsal();

  return (
    <Button variant="light" onClick={() => signOutClickHandler(instance)}>Sign Out</Button>
  );
}
export default function Navigation() {
  return (
    <>
    <Navbar bg="dark" expand="lg" className="ps-3 mb-3">
      <Navbar.Brand>
        <img alt="ONE Record Explorer" height="25px" src={logo} className="pe-3" />
        Playground
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />      
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active nav-link ps-4 pe-4' : 'nav-link ps-4 pe-4')}>Start</NavLink>
          <NavLink to="/server-information" className={({ isActive }) => (isActive ? 'active nav-link ps-4 pe-4' : 'nav-link ps-4 pe-4')}>ServerInformation</NavLink>
          <NavLink to="/logistics-objects" className={({ isActive }) => (isActive ? 'active nav-link ps-4 pe-4' : 'nav-link ps-4 pe-4')}>LogisticsObjects</NavLink>
          <NavLink to="/action-requests" className={({ isActive }) => (isActive ? 'active nav-link ps-4 pe-4' : 'nav-link ps-4 pe-4')}>ActionRequests</NavLink>
        </Nav>
        <SignOutButton />  
      </Navbar.Collapse>          
    </Navbar>    
  </>
  );
}
