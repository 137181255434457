import './App.css';
import { Col, Row } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faHouse } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ServerInformation from './components/ServerInformation';
import LogisticsObjects from './components/LogisticsObjects';
import Layout from './components/Layout';
import NoPage from './components/NoPage';
import LogisticsObjectDetails from './components/LogisticsObjectDetails';
import ActionRequests from './components/ActionRequests';
import ActionRequestDetails from './components/ActionRequestDetails';
import Start from './components/Start';
import {
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import Welcome from './components/Welcome';

library.add(fas, faHouse);

export function getONERecordServerURL() {
  const { instance, inProgress, accounts } = useMsal();
  var logisticsAgentURI;
  var oneRecordServerAPI = null;
  if (accounts !== undefined && accounts.length > 0) {
    logisticsAgentURI = accounts[0].idTokenClaims.logisticsAgentURI;    
    
    if(logisticsAgentURI !== undefined) {      
      oneRecordServerAPI = logisticsAgentURI.split("/logistics-objects/")[0];      
    }
  }  
  return oneRecordServerAPI;
}


export async function callApi(accessToken, url) { 

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/ld+json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(url, options)
    .then((response) => {      
      if (response.status <= 300) {
        return response.json()
      }
    })
    .catch((error) => console.error(error));
}

function App() { 

  const [accessToken, setAccessToken] = useState(null);
  let baseURL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    baseURL = 'http://localhost:8080';
  } else {
    // production code
    baseURL = window._ONE_RECORD_API;
  }

  



  function Pages() {
    return (
      <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Start />} />
        <Route path="server-information" element={<ServerInformation />} />        
        <Route path="logistics-objects" element={<LogisticsObjects />} />
        <Route path="logistics-objects/:logisticsObjectId" element={<LogisticsObjectDetails />} />
        <Route path="action-requests" element={<ActionRequests />} />
        <Route path="action-requests/:actionRequestId" element={<ActionRequestDetails />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
    );
  }

  return (
    <div>
      <Row>
        <Col>
        <BrowserRouter>
          <UnauthenticatedTemplate>
              <Welcome />              
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>            
              <Pages />            
            </AuthenticatedTemplate>
            </BrowserRouter>
        </Col>
      </Row>
    </div>
  );
}

export default App;
