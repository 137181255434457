import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import {    
    useMsal    
  } from "@azure/msal-react";
import { Button } from "react-bootstrap";

function signInClickHandler(instance) {  
    instance.loginRedirect();   
  }
function SignInButton() {
// useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
const { instance } = useMsal();

return <Button variant="primary" onClick={() => signInClickHandler(instance)}>Sign In</Button>;
}

export default function Welcome() {
  return (
    <>
      <Navbar bg="dark" expand="lg" className="ps-3 mb-3">
        <Navbar.Brand>
          <img
            alt="ONE Record Explorer"
            height="25px"
            src={logo}
            className="pe-3"
          />
          Playground
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              to="/"
              className={"active nav-link ps-4 pe-4"}
            >
              Welcome
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="ps-3">
        <h1>Welcome </h1>
        <p>Please login first</p>
      <SignInButton />
      </div>
      
    </>
  );
}
