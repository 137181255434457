import DateTime from "./DateTime";

export default function MovementTime({ data }) {  
  const template = [];

  if ("https://onerecord.iata.org/ns/cargo#direction" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#direction"]}
      </span>
    );
  }

  if (
    data["https://onerecord.iata.org/ns/cargo#movementTimeStamp"] !== undefined
  ) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        <DateTime
          data={data["https://onerecord.iata.org/ns/cargo#movementTimeStamp"]}
        />
      </span>
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#movementTimeType" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        ({data["https://onerecord.iata.org/ns/cargo#movementTimeType"]})
      </span>
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#movementMilestone" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        ({data["https://onerecord.iata.org/ns/cargo#movementMilestone"]})
      </span>
    );
  }

  return <>{template}</>;
}
