import LogisticsObject from "./LogisticsObject";

export default function HandlingInstructions({ key, data }) {
  const template = [];
  if ("https://onerecord.iata.org/ns/cargo#handlingInstructionsType" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#handlingInstructionsType"]}
      </span>
    );
  }
  if (
    "https://onerecord.iata.org/ns/cargo#handlingInstructionsTypeCode" in data
  ) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {
          data[
            "https://onerecord.iata.org/ns/cargo#handlingInstructionsTypeCode"
          ]
        }
      </span>
    );
  }
  if ("https://onerecord.iata.org/ns/cargo#description" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>
        {data["https://onerecord.iata.org/ns/cargo#description"]}
      </span>
    );
  }
  if ("https://onerecord.iata.org/ns/cargo#requestedByActor" in data) {
    if (template.length > 0) {
      template.push(<br />);
    }
    template.push(
      <span style={{ paddingRight: "5px" }}>
        Requested by{" "}
        <LogisticsObject
          data={data["https://onerecord.iata.org/ns/cargo#requestedByActor"]}
        />
      </span>
    );
  }

  return <>{template}</>;
}
