import { useMsal } from "@azure/msal-react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Start() {
  const { accounts } = useMsal();
  var logisticsAgentURI;
  var oneRecordServerAPI;
  if (accounts !== undefined && accounts.length > 0) {
    logisticsAgentURI = accounts[0].idTokenClaims.logisticsAgentURI;    
    
    if(logisticsAgentURI !== undefined) {      
      oneRecordServerAPI = logisticsAgentURI.split("/logistics-objects/")[0];
    }
  }  
  return (
    <div>
      <Row>
        <Col xs={2} />
        <Col xs={8}>
          <div className="list-heading">Start</div>
          <div className="pt-4">
            <p>Hello and welcome to the ONE Record Playground.</p>
            <p>
              We have prepared the following following examples for an easy
              start in ONE Record.
            </p>            
            <hr/>
            <p><u>Your DataOwner URI:</u> <br/>{logisticsAgentURI}</p>            
            <p><u>Your ONE Record Server:</u> <br/>{oneRecordServerAPI}</p>
            <hr/>
            <h2>Examples</h2>

            <h3>Various Logistics Objects</h3>
            <ul>
              <li>
                <Link to="/logistics-objects/piece-0">piece-0</Link><br/>
                Piece with only goodsDescription property
              </li>
              <li>
                <Link to="/logistics-objects/company-lhind">company-lhind</Link><br/>
                Company with contact persons
              </li>
              <li>
                <Link to="/logistics-objects/uld-pmc31490ds">
                  uld-pmc31490ds
                </Link>
                <br/>
                ULD pallet
              </li>
            </ul>

            <h3>Shipment</h3>
            <ul>
              <li>
                <Link to="/logistics-objects/shipment-1">shipment-1</Link><br/>
                Shipment with a property and 2 linked pieces
                </li>
              
              <li>
                <Link to="/logistics-objects/piece-1">piece-1</Link><br/>
                Piece with properties, incl. an nested embedded object
              </li>
              <li>
                <Link to="/logistics-objects/piece-2">piece-2</Link><br/>
                Piece with properties, incl. a list of embedded objects
              </li>
            </ul>

            <h3>Air Waybill (incl. Links to Booking, Activities, etc.)</h3>
            <ul>
              <li>
                <Link to="/logistics-objects/waybill-awb-020-12345675">
                  waybill-awb-020-12345675
                </Link>
                <br />
                Air Waybill (incl. Booking, Activities, etc.)
              </li>
            </ul>

            <h3>External References</h3>
            <ul>
              <li>
                <Link to="/logistics-objects/transport-means-0">
                  transport-means-0
                </Link>
                <br />
                Transport Means with Linked External Reference to Picture
              </li>
              <li>
                <Link to="/logistics-objects/shipment-2">shipment-2</Link>
                <br />
                Shipment with Linked External Reference to Picture
              </li>
            </ul>

            <h3>Logistics Events</h3>
            <ul>
              <li>
                <Link to="/logistics-objects/shipment-3">shipment-3</Link>
                <br />
                Shipment with 2 linked Piece and LogisticsEvents (Actual BKD on
                Shipment-Level, Actual RCS on Shipment- and Piece-Level)
              </li>              
              <li>
                <Link to="/logistics-objects/shipment-4">shipment-4</Link>
                <br />
                Shipment with 5 linked Pieces with Actual Booked
                (Shipment-Level) and Planned Events on Shipment- and Piece-Level
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
}
