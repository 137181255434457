export default function Value({ data }) {
  if (data.constructor === Object) {
    if (
      ('@type' in data
        && typeof data['@type'] === 'string'
        && data['@type'].startsWith('https://onerecord.iata.org/')
        && data['@type'].endsWith('Value'))
      || ('@type' in data
        && Array.isArray(data['@type'])
        && data['@type'][0].startsWith('https://onerecord.iata.org/')
        && data['@type'][0].endsWith('Value'))
    ) {
      let numericalValue = '';
      if (
        typeof data['https://onerecord.iata.org/ns/cargo#numericalValue']
        === 'string'
      ) {
        numericalValue = data['https://onerecord.iata.org/ns/cargo#numericalValue'];
      } else {
        numericalValue = data['https://onerecord.iata.org/ns/cargo#numericalValue']['@value'];
      }
      return (
        <span>
          {parseFloat(numericalValue).toFixed(2)}
          {' '}
          {data['https://onerecord.iata.org/ns/cargo#unit']}
        </span>
      );
    }
  }
  return <>{data}</>;
}
