import './CustomNode.css';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';

const CustomNode = ({ data, isConnectable, sourcePosition = Position.Bottom }) => (
  <>
    {data?.label}
    <Handle type="source" position={sourcePosition} isConnectable={isConnectable} />
  </>
);

CustomNode.displayName = 'CustomNode';

export default memo(CustomNode);