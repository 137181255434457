import { useState, useEffect } from 'react';
import {
  Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import PlainJSONLD from './LinkedDataViewer/PlainJSONLD';
import JSONLDTable from './LinkedDataViewer/JSONLDTable';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { callApi } from '../App';

export default function ActionRequestDetails() {
  const { instance, inProgress, accounts } = useMsal();
  var logisticsAgentURI;
  var oneRecordServerAPI;
  if (accounts !== undefined && accounts.length > 0) {
    logisticsAgentURI = accounts[0].idTokenClaims.logisticsAgentURI;    
    
    if(logisticsAgentURI !== undefined) {      
      oneRecordServerAPI = logisticsAgentURI.split("/logistics-objects/")[0];
    }
  }  

  const { actionRequestId } = useParams();

  const url = `${oneRecordServerAPI}/action-requests/${actionRequestId}`;

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {    

    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    if (!data && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.idToken;          
          setLoading(true)
          callApi(accessToken, `${url}`).then((response) => {            
            setData(response);
            setLoading(false);
          });

          callApi(accessToken, `${logisticsEventsURL}`).then((response) => {            
            setLogisticsEvents(response);      
          });

      
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error(error);
        });
    }
  }, [instance, accounts, inProgress, data]);

  if (isLoading) return <p>Loading...</p>;
  if (!data) return <p>Data could not be loaded</p>;

  const tableIcon = <FontAwesomeIcon icon={['fas', 'table']} />;
  const codeIcon = <FontAwesomeIcon icon={['fas', 'code']} />;

  return (
    <div>
      <Row>
        <Col md={2} xs={false} />
        <Col md={8} xs={12}>
          <div className="list-heading">
            ActionRequests
            <FontAwesomeIcon
              icon={['fas', 'caret-right']}
              className="ps-1 pe-1"
            />
            <span style={{ fontWeight: 'normal' }}>
              {' '}
              {url}
            </span>
          </div>
          <div className="pt-4">
            <Tabs
              defaultActiveKey="tabular-view"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="tabular-view"
                title={(
                  <span>
                    {tableIcon}
                    {' '}
                    Table
                  </span>
)}
              >
                <JSONLDTable data={data} />
              </Tab>
              <Tab
                eventKey="json-ld-view"
                title={(
                  <span>
                    {codeIcon}
                    {' '}
                    JSON-LD
                  </span>
)}
              >
                <PlainJSONLD data={data} />
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
}
