import { Link } from "react-router-dom";

export default function LogisticsObject({ data }) {
  if (data.constructor === Object) {
    if ("@id" in data && typeof data["@id"] === "string") {
      if (data["@id"].startsWith("http://") || data["@id"].startsWith("https://")) {
        if (data["@id"].includes("/logistics-objects/")) {
          const logisticsObjectId = data["@id"].split("/logistics-objects/").pop();
          return (
            <Link to={`/logistics-objects/${logisticsObjectId}`}>{data["@id"]}</Link>
          );
        }        
      }
    }
  }
  return <>{data}</>;
}
