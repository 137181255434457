export default function Country({ key, data }) {
    const template = [];
    if ("https://onerecord.iata.org/ns/cargo#countryName" in data) {
      template.push(
        <span style={{ paddingRight: "5px"}}>
          {data["https://onerecord.iata.org/ns/cargo#countryName"]}          
        </span>
      );
    }
    if ("https://onerecord.iata.org/ns/cargo#countryCode" in data) {
      template.push(
        <span style={{ paddingRight: "5px"}}>
          ({data["https://onerecord.iata.org/ns/cargo#countryCode"]})
        </span>
      );
    }
  
    return <>{template}</>;
  }
  