import Value from './Value';

export default function Dimensions({ data }) {
  const template = [];
  if ('https://onerecord.iata.org/ns/cargo#height' in data) {
    template.push(
      <span style={{ paddingRight: '5px' }}>
        Height:
        {' '}
        <Value data={data['https://onerecord.iata.org/ns/cargo#height']} />
      </span>,
    );
  }
  if ('https://onerecord.iata.org/ns/cargo#length' in data) {
    template.push(
      <span style={{ paddingRight: '5px' }}>
        Length:
        {' '}
        <Value data={data['https://onerecord.iata.org/ns/cargo#length']} />
      </span>,
    );
  }
  if ('https://onerecord.iata.org/ns/cargo#width' in data) {
    template.push(
      <span style={{ paddingRight: '5px' }}>
        Width:
        {' '}
        <Value data={data['https://onerecord.iata.org/ns/cargo#width']} />
      </span>,
    );
  }

  return <>{template}</>;
}
