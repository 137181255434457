import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Node from './Node';

export default function JSONLDTable({ data }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Property</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map((key, index) => (
          <tr key={key}>
            <td>
              <OverlayTrigger overlay={<Tooltip id={key}>{key}</Tooltip>}>
                <div>{key.split('#').pop()}</div>
              </OverlayTrigger>
            </td>
            <td>
              <Node data={data[key]} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

  );
}
